import React, { Component } from 'react';
import { navigate } from 'gatsby';

import Autocomplete from '../components/autocomplete';

import './searchbar.css';

class SearchBar extends Component {
    state = {
        query: '',
    };

    onSuggestionSelected = (_, { suggestion }) => {
        this.setState({
            query: suggestion._source.name,
        });
        navigate('/nl/' + suggestion._source.slug + '/');
    };

    onSuggestionCleared = () => {
        this.setState({
            query: '',
        });
    };

    render() {
        return (
            <div className="searchbar">
                <Autocomplete
                    delay={process.env.SUGGESTION_DELAY || 700}
                    onSuggestionSelected={this.onSuggestionSelected}
                    onSuggestionCleared={this.onSuggestionCleared}
                />
            </div>
        );
    }
}

export default SearchBar;
