import React, { Component } from 'react';
import { navigate } from 'gatsby';
import AutoSuggest from 'react-autosuggest';
import slugify from 'slugify';

class AutoComplete extends Component {
    state = {
        value: '',
        suggestions: [],
    };

    timerId = null;

    onChange = (_, { newValue }) => {
        console.log('onChange: ' + newValue);
        this.setState({
            value: newValue,
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
            fetch(
                `${process.env.SUGGESTION_URL ||
                    'https://api.slovo.app'}/verbs/${value}`
            )
                .then(response => response.json())
                .then(data => this.setState({ suggestions: data.hits.hits }));
        }, this.props.delay);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    getSuggestionValue(hit) {
        return hit._source.name;
    }

    renderSuggestion(hit) {
        return <span>{hit._source.name}</span>;
    }

    onKeyDown(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            if (e.target.value) {
                navigate('/nl/' + slugify(e.target.value) + '/');
            }
        }
    }

    render() {
        const { onSuggestionSelected } = this.props;
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: 'Zoek ...',
            onChange: this.onChange,
            onKeyDown: this.onKeyDown, // CAPTURE KEY PRESSES
            enterkeyhint: 'search',
            value,
        };

        return (
            <AutoSuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

export default AutoComplete;
